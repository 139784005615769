import React from 'react';
import styles from '../styles/PlacementSection.module.css';

const PlacementSection = () => {
  return (
    <section className={styles.placementSection}>
      <div className={styles.overlay}>
        <h1 className={styles.title}>Simplify College Placements</h1>
        <p className={styles.subtitle}>
          Empower your institution with streamlined building <br />
          placement solutions. Make the process effortless for students and administrators alike.
        </p>
      </div>
    </section>
  );
};

export default PlacementSection;
