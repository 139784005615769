import React, { useState } from "react";
import styles from "../styles/Navbar.module.css"; // Import the CSS module

const Navbar = () => {
  // State to manage the visibility of the dropdown menu
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className={styles.navbar}>
      {/* Logo Text */}
      <div className={styles.logo}>
        Campus<span className={styles.wise}>Wise</span>
      </div>
      <button className={`${styles.contactButton} ${styles.desktopButton}`}>
        Contact Us
      </button>

      {/* Three-line (hamburger) menu icon */}
      <div className={styles.hamburger} onClick={toggleDropdown}>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>

      {/* Dropdown menu for mobile view */}
      {isDropdownOpen && (
        <div className={styles.dropdown}>
          <button className={styles.contactButton}>Contact Us</button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
