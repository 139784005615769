import React from "react";
import img1 from "../images/img1.png";
import styles from "../styles/Header.module.css"; 

const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.textSection}>
        <h5 className={styles.tagline}>Workday for College Campus</h5>
        <h1 className={styles.title}>Understand your placement data better</h1>
        <p className={styles.description}>
          We help you turn data into metrics and metrics into insights to
          improve your placement results and take informed decisions for
          betterment of students and reap better than ever results.
        </p>
        <button className={styles.button}>Get Started</button>
      </div>
      <div className={styles.imageSection}>
        <img src={img1} alt="Man working on a computer" className={styles.image} />
      </div>
    </div>
  );
};

export default Header;
