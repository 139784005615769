import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import StreamlineSection from './components/StreamlineSection';
import BackedBy from './components/BackedBy';
import ServicesSection from './components/ServicesSection';
import PlacementSection from './components/PlacementSection';
import AboutUsSection from './components/AboutUsSection';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
    <Navbar/>
     <Header/>
     <StreamlineSection />
      <PlacementSection/>
     <BackedBy/>
     <ServicesSection/>
     <AboutUsSection/>
     <Footer/>
    </div>
  );
}

export default App;
