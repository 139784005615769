import React from 'react';
import styles from '../styles/ServicesSection.module.css';
import img1 from '../images/img3.png'; 
import img2 from '../images/img4.png';

const ServicesSection = () => {
  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.heading}>For Colleges</h2>
      <div className={styles.servicesGrid}>
        <div className={styles.serviceCard}>
          <img src={img1} alt="Campus Placement Portal" className={styles.serviceImage} />
          <h3>Campus Placement Portal</h3>
          <p className={styles.para}>
            Our Campus Placement Portal helps colleges streamline their recruitment process.
            It provides tools for managing job postings, applications, and interviews efficiently.
          </p>
          <p className={styles.para}>
            We give them essential data insights to improve placement metrics with our proprietary AI and ML algorithms.
          </p>
        </div>
        <div className={styles.serviceCard}>
          <img src={img2} alt="Alumni Engagement" className={styles.serviceImage} />
          <h3>Alumni Engagement</h3>
          <p className={styles.para}>
            We help organizations foster strong connections with their alumni. Our software tools make it simple to communicate,
            organize events, and keep alumni engaged.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
