import React from "react";
import styles from "../styles/StreamlineSection.module.css"; // Importing the CSS module

const StreamlineSection = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Streamline Your Campus Operations with CampusWise</h1>
      <p className={styles.description}>
        CampusWise offers software solutions to simplify campus management. Our tools help you manage student data and schedules more efficiently—Trust CampusWise for all your campus software needs.
      </p>
    </div>
  );
};

export default StreamlineSection;
