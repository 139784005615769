import React from 'react';
import styles from '../styles/Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          {/* Contact Form */}
          <div className={styles.contactForm}>
            <form>
              <div className={styles.formGroup}>
                <input type="text" name="name" placeholder="Name" className={styles.inputField} required />
                <input type="email" name="email" placeholder="Email" className={styles.inputField} required />
              </div>
              <textarea name="message" placeholder="Message" className={styles.textarea} required></textarea>
              <button type="submit" className={styles.submitButton}>Submit</button>
            </form>
          </div>
          
          {/* Text Section */}
          <div className={styles.textSection}>
            <h2>Contact Us Today</h2>
            <p>
              Reach out to us using the contact form. We are here to help with your building placement needs.
            </p>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className={styles.footerBottom}>
        <span>&#9679; Campuswise</span>
      </div>
    </footer>
  );
};

export default Footer;
