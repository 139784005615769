import React from "react";
import styles from "../styles/BackedBy.module.css"; // Import the CSS module
import womanWithLaptop from "../images/women.png"; // Replace with the actual path to the image
import microsoftLogo from "../images/microsoft.png"; // Replace with the actual path to the logo

const BackedBy = () => {
  return (
    <div className={styles.container}>
      {/* Image Section */}
      <div className={styles.imageSection}>
        <img
          src={womanWithLaptop}
          alt="Portrait of an attractive empowered multiethnic woman looking at the camera and smiling, businesswoman at work, IT manager, software engineering professional"
          className={styles.image}
        />
      </div>
      
      {/* Backed By Section */}
      <div className={styles.backedBySection}>
        <h2 className={styles.heading}>Backed by</h2>
        <img
          src={microsoftLogo}
          alt="Microsoft for Startups"
          className={styles.logo}
        />
      </div>
    </div>
  );
};

export default BackedBy;
