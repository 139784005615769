import React from 'react';
import styles from '../styles/AboutUsSection.module.css';
import aboutUsImage from '../images/aboutus.png'; // Replace with your actual image path

const AboutUsSection = () => {
  return (
    <section className={styles.aboutUsSection}>
      <div className={styles.contentWrapper}>
        <div className={styles.imageContainer}>
          <img src={aboutUsImage} alt="About Us" className={styles.aboutUsImage} />
        </div>
        <div className={styles.textContainer}>
          <h2 className={styles.heading}>About Us</h2>
          <p>
            We are a group of folks from NIT and Guru Gobind Singh Indraprastha University
            building campus solutions for problems we faced ourselves.
          </p>
          <p>
            That's why, we are the best partner for your campus solutions.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
